var render = function render(){var _vm=this,_c=_vm._self._c;return ((_vm.owner.channels_as_invited_presenter || _vm.channels_as_owner) && (_vm.owner.channels_as_invited_presenter.length || _vm.channels_as_owner.length))?_c('div',{staticClass:"mChannel__channels__footer"},[_c('div',{staticClass:"channel__list__wrapp mChannel__section"},[(_vm.channels_as_owner.length)?_c('div',{staticClass:"channel__list__label"},[_vm._m(0)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"channel__list"},_vm._l((_vm.channels_as_owner),function(channel){return _c('div',{key:channel.id,staticClass:"channel__tile"},[_c('channel-tile',{staticClass:"full__width",attrs:{"channels-plans":channel.subscription && [{
                        id: channel.id,
                        plans: channel.subscription.plans
                    }],"exist-channel":channel}})],1)}),0),_vm._v(" "),(_vm.owner.channels_as_invited_presenter.length)?_c('div',{staticClass:"channel__list__label"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('components.modals.template.owner_creator_footer_template.creator_at', {creator_upper: _vm.$t('dictionary.creator_upper')})))])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"channel__list"},_vm._l((_vm.owner.channels_as_invited_presenter),function(channel){return _c('router-link',{key:channel.id,staticClass:"channel__tile",attrs:{"to":{ name: 'channel-slug',params: { id: channel.relative_path.split('/')[2], slug: channel.relative_path.split('/')[3] }}}},[_c('channel-tile',{attrs:{"channels-plans":channel.subscription && [{
                        id: channel.id,
                        plans: channel.subscription.plans
                    }],"exist-channel":channel}})],1)}),1)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("Owner")])])
}]

export { render, staticRenderFns }